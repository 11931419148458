body,
html {
  height: 100%;
  background-color: #e9ecef !important;
  font-family: Roboto !important;
}

.navbar_ppc {
  background-color: #333333;
}

.jumbotron {
  margin-bottom: 0px !important;
}

.btn-secondary {
  margin: 5px;
  background-color: #333333 !important;
  border-color: #333333 !important;
}

.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary.active {
  background-color: #5a5a5a !important;
}

.btn-group > .btn.active,
.btn-group > active {
  z-index: auto !important;
}

.footer {
  width: 100%;
  max-width: 100%;
  position: fixed;
  bottom: 0;
  height: 50px;
  margin-top: -50px;
  text-align: center;
}

.loader {
  position: fixed;
  z-index: 999;
  height: 100px;
  width: 100px;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.logo {
  width: 15rem;
  height: 5rem;
}

.logo-flex-container {
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .logo {
    width: 10rem;
    height: 4rem;
  }
  .logo-flex-container {
    justify-content: center;
  }
  .donate_addr {
    padding: 0 !important;
  }
}

@media screen and (max-width: 990px) {
  .donate_addr {
    font-size: 0.79rem !important;
  }
}
